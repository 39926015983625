

export const deleteNullProperties = (object) => {
  for (let key in object) {
    if (object[key] === null || object[key] === undefined) {
      delete object[key];
    }
  }
};

export const autoLink = (str) => {
  if (typeof str !== 'string') {
    return str;
  }
  const regexp_url = /((h?)(ttps?:\/\/[a-zA-Z0-9.\-_@:/~?%&;=+#',()*!]+))/g; // ']))/;
  const regexp_makeLink = function(all, url, h, href) {
    return '<a href="h' + href + '" target="_blank">' + url + '</a>';
  };

  return str.replace(regexp_url, regexp_makeLink);
};

export const getThumbnailUrl = (thumbnail) => {
  if (!thumbnail) {
    return false;
  }

  if (typeof thumbnail !== 'object') {
    return false;
  }

  return thumbnail.thumbnail_url;
};

export const handlepaste = (text) => {

  const selection = window.getSelection();
  const range = selection.getRangeAt(0);
  range.deleteContents();

  const node = document.createElement('span');
  // node.style.whiteSpace = 'pre';
  node.innerHTML = text;
  range.insertNode(node);

  // 挿入文字列の末尾にカーソルを移動させる
  selection.collapseToEnd();
  };

export const dateCheck = (date) => {
  let todaysDate = new Date();
  let checkDate = new Date(date);
  let onTime = todaysDate < checkDate;
  return onTime;
}

export const nextDateCheck = (date) => {
	let checkNextDate = new Date(date);
  let todaysDate = new Date();
	let onTime = todaysDate.getTime() < checkNextDate.getTime();
  return onTime;
}

export const move = (path) => {
  location.href = path
}

