<template>
  <router-link :to="'/search/?tag='+tagName" :class="className">
      #{{ tagName }}
  </router-link>
</template>
<script>
export default {
  name: 'Tag',
  props: {
    tagName: String,
    id: Number,
    className: String
  }
}
</script>
<style scoped>
a {
	background-color: #f5f4f2;
	border-radius: 4rem;
	color: #000;
	display: inline-block;
	font-family: YuGothic, "Yu Gothic Medium", "Yu Gothic", Meiryo, sans-serif;
	font-size: 1.1rem;
	letter-spacing: .11rem;
	padding: .8rem 1rem;
}
.blue a {
	background-color: #e5e7ee;
}
.orange a {
	background-color: #e8e2d7;
}
.green a {
	background-color: #e5e7dc;
}
.white a {
  background-color: #ffffff;
}
@media only screen and (max-width:768px) {
	a {
		border-radius: 2rem;
		font-size: .9rem;
		letter-spacing: .09rem;
		padding: .8rem 1.2rem;
	}
}
</style>