<template>
  <a>
    <div>
      <div @click="clickRead(notification.id)">
        <time :datetime="moment(notification.created_at).format('YYYY.MM.DD HH:mm')">{{moment(notification.created_at).format('YYYY.MM.DD HH:mm')}}</time>
        <p class="notification-title">{{notification.title}}</p>
      </div>
      <p class="notification-content" v-show="isOpenChild" v-html="nl2br(notification.content)"/>
    </div>
  </a>
</template>
<script>
import {ref} from "vue";
import {autoLink} from "@/utils/helper";
import {nl2br} from "@/utils/nl2br";
import moment from "moment";

export default {
  name: 'LinkButton',
  props: {
    notification: {
      type: Object,
      required: true
    },
    onClickRead: {
    type: Function,
    required: true
    }
  },
  setup(props){
    const isOpenChild = ref(false);
    const toggleChild = () => {
      isOpenChild.value = !isOpenChild.value
    }
    const clickRead = (id) => {
      if(props.notification.checked === 0){
        props.onClickRead(id);
      }
      toggleChild();
    }
    return {
      moment,
      isOpenChild,
      toggleChild,
      clickRead,
      autoLink,
      nl2br
    }
  }
}
</script>
<style scoped>

a, time {
	cursor: pointer;
	display: block;
}
a:hover {
	opacity: 1;
}
time {
	color: #000;
	font-family: "aw-conqueror-didot", serif;
	font-size: 13px;
	letter-spacing: 1.1px;
	margin-bottom: 9px;
}
.notification-title {
	color: #000;
	font-size: 14px;
	font-weight: bold;
	margin-bottom: 8px;
}
.notification-title, .notification-content {
	color: #000;
	font-family: YuGothic, "Yu Gothic Medium", "Yu Gothic", Meiryo, sans-serif;
	font-size: 13px;
	letter-spacing: 1.3px;
	line-height: 1.6;
	word-break: break-all;
}

.notification-content {
	border-bottom: 1px solid #ddd;
	border-top: 1px solid #ddd;
	padding: 10px 0 10px 2px;
}
</style>