import {ref, reactive, toRefs } from 'vue';
// import {useCommons} from "./useCommons";

// const {loading} = useCommons();

// 管理したいステートを定義
const state = reactive({
  isLoggedIn: false,
  isAdmin: false,
  isTemple: false,
  user: {}
});


export const useAuth = () => {

  const initialize = () => {
    const localState = JSON.parse(localStorage.getItem('auth'))
    if(localState){
      state.user = localState.user;
      state.isAdmin = localState.isAdmin;
      state.isTemple = localState.isTemple;
      state.isLoggedIn = localState.isLoggedIn;
      checkLoginUser()
    }else{
      getLoginUser()
    }
  }

  const error = ref(null)

  // ユーザ情報取得
  const getLoginUser = async () => {
    // loading.value = true;
    try {
      let data = await fetch('/api/login-user',
        {credentials: "same-origin"});
      if (!data.ok) {
        state.isLoggedIn = false;
        localStorage.removeItem('auth')
        if (data.status === 404){
          return
        }else{
          throw Error("No data available");
        }
      }
      const user = await data.json()
      setLoginUser(user.data)
    } catch (err) {
      error.value = err.message;
      console.log(error.value);
    }
    // loading.value = false;
  };

  // ユーザ情報取得
  const checkLoginUser = async () => {
    // loading.value = true;
    try {
      let data = await fetch('/api/login-user',
        {credentials: "same-origin"});
      if (!data.ok) {
        localStorage.removeItem('auth')

        throw Error("No data available");
      }
      const user = await data.json()
      setLoginUser(user.data)
    } catch (err) {
      error.value = err.message;
      console.log(error.value);
      location.reload()
    }
    // loading.value = false;
  };

  const logout = () => {
    // toDo: logout APIを呼ぶ
    state.user = {}
  }

  const setLoginUser = (user) => {
    state.user = user;
    state.isLoggedIn = true;
    if(state.user.id === 10000){
      state.isAdmin = true;
    }
    if(state.user.monk_type_id === 3 || state.isAdmin){
      state.isTemple = true
    }

    // localstorageに保存しておく
    localStorage.setItem("auth", JSON.stringify(state))
  }

  // フォロー
  const followTemple = templeId => {
    if(!state.isLoggedIn){
      location.href = '/users/login'
      return false;
    }
    if(isFollowing(templeId)){
      return false
    }

    const data = { userId:state.user.id, templeId:templeId}
    state.user.followee.push({temple_id: templeId})

    fetch(`/api/follow/`, {
      method: 'POST',
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data)
      })
      .catch((error) => {
        console.log(error);
        state.user.followee = state.user.followee.filter(temple => temple.temple_id !== templeId);
      });
  }

  const readNotification = (id) => {
    const notification = {checked: 1, id: id}

    fetch(`/api/notifications/${id}`, {
      method: 'PUT',
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(notification)
    })
      .then((response) => {
        if (!response.ok) {
          console.log('!response.ok', response);
          return response.json().then(json => {throw json.errors})
        }
        return response.json()
      })
      // .then((response) => response.json())
      .then((data) => {
        console.log(data);
        state.user.notifications = state.user.notifications.map(notification => {
          if(notification.id == id){
            notification.checked = 1;
            return notification;
          }else{
            return notification
          }
        })
      })
      .catch((err) => {
        console.log(err.message)
      });
  }

  const hasArticleAuth = (articleId, templeId) => {
    if(!state.isLoggedIn) {
      return false
    }

    if(state.user.temple && Number(state.user.temple.temple_id) === Number(templeId)){
      return true
    }else{
      return state.isAdmin || state.user.readable_article_ids.map(object => object.article_id).includes(Number(articleId))
    }

  }

  const hasTempleAuth = templeId => {
    if(!state.isLoggedIn){
      return false
    }
    return state.isAdmin || Number(state.user.temple.temple_id) === Number(templeId)
  }

  const hasPartnerAuth = partnerId => {
    if(!state.isLoggedIn){
      return false
    }

    if(!state.user.partner){
      return false
    }

    return state.isAdmin || Number(state.user.partner.partner_id) === Number(partnerId)
  }

  const isFollowing = (templeId) => {
    if(!state.isLoggedIn){
      return false
    }

    return !!state.user.followee.find(temple => temple.temple_id === templeId)
  }

  const hasReserved = eventDetailId => {
    if(!state.isLoggedIn){
      return false
    }
    return !!state.user.reserved_event_ids.find(event => event.event_detail_id === eventDetailId)
  }

  return {
    ...toRefs(state),
    getLoginUser,
    logout,
    initialize,
    readNotification,
    followTemple,
    hasArticleAuth,
    hasPartnerAuth,
    hasTempleAuth,
    isFollowing,
    hasReserved
  }
}

