<template>
  <div class="overlay" v-if="showModal">
    <div :class="['content', error && 'error']">
      <template v-if="title">
        <div class="modal-title" v-html="title"></div>
        <div class="modal-text" v-html="text"></div>
      </template>
      <template v-else>
        <div class="modal-title" v-html="text"></div>
      </template>

      <button class="modal-btn cancel" @click="cancel">
        <span class="modal-btn__cancel">閉じる</span>
      </button>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';

export default {
  name: 'Modal',
  props: {},
  setup() {
    const showModal = ref(true);
    const cancel = () => {
      showModal.value = false;
    };
    const title = window.modalTitle;
    const text = window.modalText;
    const error = window.modalError;

    return {
      showModal,
      error,
      cancel,
      text,
      title,
    };
  },
};
</script>

<style lang="scss" scoped>
.overlay {
	align-items: center;
	background-color: rgba(0, 0, 0, .7);
	display: flex;
	height: 100%;
	justify-content: center;
	left: 0;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 1000;
}

.content {
	background: #fff;
	border-radius: 30px;
	@include sp {
		padding: 10vw;
		width: 86.6666vw;
	}
	padding: 80px;
	text-align: center;
	width: 650px;
	z-index: 1100;
	&.error {
		border: 1px solid red;
	}
}

.modal {
	color: #412814;
	-webkit-font-smoothing: antialiased;
	&-title {
		color: #412814;
		font-size: 3rem;
		// font-family: $contents_fonts_jp_Mincho;
		@include font_shuei_400;
		@include sp {
			font-size: 1.5rem;
		}
		line-height: 1.5;
		margin-bottom: 4rem;
		position: relative;

		&::before {
			background: url(../assets/image/wave.png) no-repeat;
			bottom: -3.6rem;
			content: "";
			display: inline-block;
			height: 4px;
			@include sp {
				bottom: -20px;
			}
			left: 50%;
			position: absolute;
			transform: translateX(-50%);
			width: 77px;
		}
	}

	&-text {
		color: #412814;
		font-size: 1.5rem;
		@include font_shuei_400;
		@include sp {
			font-size: 1.3rem;
			line-height: 2;
			margin-top: 40px;
		}
		line-height: 1.5;
		margin-top: 90px;
	}
	&-btn {
		background-color: #fff;
		border: 1px #412814 solid;
		border-radius: 40px;
		color: #412814;
		font-family: $contents_fonts_jp_Gothic;
		font-size: 1.5rem;
		@include sp {
			font-size: 1.3rem;
			line-height: 48px;
			margin-top: 0;
			width: 156px;
		}
		line-height: 64px;
		margin-top: 50px;
		width: 200px;

		&.cancel {
			@include sp {
				margin-top: 18px;
			}
			// margin-right: 1.944vw;
		}

		&.submit {
			@include sp {
				margin-top: 15px;
			}
			&.saving {
				background-color: #412814;
				color: #fff;
			}
		}

		& .modal-btn__cancel {
			height: 4px;
			padding-left: 35px;
			position: relative;
			width: 20px;

			&::before {
				background: #412814;
				content: "";
				display: inline-block;
				height: 1px;
				left: 0;
				position: absolute;
				top: 50%;
				transform: rotate(45deg) translateY(-50%);
				vertical-align: middle;
				width: 20px;
			}

			&::after {
				background: #412814;
				content: "";
				height: 1px;
				left: 0;
				position: absolute;
				top: 50%;
				transform: rotate(-45deg) translateY(-50%);
				width: 20px;
			}
		}

		& .modal-btn__submit {
			padding-left: 8px;
			position: relative;

			&-img {
				height: 15px;
				vertical-align: middle;
				width: 15px;
			}
		}
	}
}
</style>
