<template>
  <li class="borderd-list">
    <div @click="toggleChild" class="parent">
      <slot name="parent"></slot>
    </div>
    <slot name="child" v-if="isOpenChild"></slot>
  </li>
</template>
<script>
import {ref} from "vue";

export default {
  name: 'LinkButton',
  props: {
    link: {
      type: Boolean
    }
  },
  setup(){
    const isOpenChild = ref(true);
    const toggleChild = () => {
      isOpenChild.value = !isOpenChild.value
    }
    return {
      isOpenChild,
      toggleChild
    }
  }
}
</script>
<style scoped>
</style>