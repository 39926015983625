/**
 * 改行コードを<br>に変換
 *
 * @param {String} str 変換したい文字列
 */

export const nl2br = (str) => {
  if(typeof(str) === "string"){
    return str.replace(/\n/g, '<br>');
  }else{
    return str
  }
}