<template>
  <template v-if="ssr">
    <a class="category_link" v-if="type === 'textOnly'" :href="'/search?category=' + id">
      {{ categoryName }}
    </a>
    <a class="category_link" v-else :href="'/search?category=' + id"> ー {{ categoryName }} </a>
  </template>
  <template v-else>
    <router-link class="category_link" v-if="type === 'textOnly'" :to="'/search?category=' + id">
      {{ categoryName }}
    </router-link>
    <router-link class="category_link" v-else :to="'/search?category=' + id">
      ー {{ categoryName }}
    </router-link>
  </template>
</template>
<script>
export default {
  name: 'Category',
  props: {
    categoryName: String,
    id: Number,
    type: String,
    ssr: Boolean,
  },
};
</script>
<style scoped>
a {
	color: #000;
	display: block;
	font-family: YuGothic, "Yu Gothic Medium", "Yu Gothic", Meiryo, sans-serif;
	font-size: 1.3rem;
	letter-spacing: .11rem;;
}

.category_link {
	color: #000;
	display: block;
	font-family: YuGothic, "Yu Gothic Medium", "Yu Gothic", Meiryo, sans-serif;
	font-size: 1.3rem;
	letter-spacing: .11rem;;
}
</style>
