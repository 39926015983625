import { createApp } from 'vue'
import vClickOutside from "click-outside-vue3"
import "./utils/font.js"
import HeaderSsr from "./components/HeaderSsr";
import FooterSsr from "./components/FooterSsr";
import ModalSsr from "./components/ModalSsr";

const header = createApp(HeaderSsr)
header.use(vClickOutside).mount('#header-ssr')

const footer = createApp(FooterSsr)
footer.mount('#footer-ssr')

const modal = createApp(ModalSsr)
modal.mount('#modal-ssr')
